const firebaseConfig = {
  apiKey: 'AIzaSyCvNeMneOSK-sXzUW5ukMFWPMMlwuThhlI',
  authDomain: 'just-chill-d.firebaseapp.com',
  projectId: 'just-chill-d',
  storageBucket: 'just-chill-d.appspot.com',
  messagingSenderId: '361814287814',
  appId: '1:361814287814:web:738430fb197169031cbec7',
  measurementId: 'G-XM4MNZY9NE',
};

export default firebaseConfig;